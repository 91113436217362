






import {Component, Prop, Vue, Watch} from "vue-property-decorator";
@Component({
  components: {}
})
export default class CountDownTimer extends Vue {
  now: Date | null = null;
  timer: any | null = null;

  @Prop({default: new Date(), required: true})
  startDate!: Date

  @Prop({default: new Date(), required: true})
  endDate!: Date

  mounted() {
    this.now = this.$props.startDate;
  }

  get hours() {
    //@ts-ignore
    let h = Math.trunc((this.$props.endDate - this.now) / 1000 / 3600);
    return h>9?h:'0'+h;
  }

  get min(){
    //@ts-ignore
    let m = Math.trunc((this.$props.endDate - this.now) / 1000 / 60) % 60;
    return m>9?m:'0'+m;
  }

  get sec(){
    //@ts-ignore
    let s = Math.trunc((this.$props.endDate - this.now)/1000) % 60
    return s>9?s:'0'+s;
  }

  @Watch('endDate', { immediate: true })
  endDateModified(newVal: any) {

    this.timer = setInterval(()=>{
      this.now = new Date()
      if(this.now > newVal){
        this.now = newVal
        this.$events.fire('time-ended');
        clearInterval(this.timer)
      }
    }, 1000)
  }
}
